const env = process.env;

const API_ROOT_URL = (document.location.hostname === 'localhost') ? 'http://localhost:8090' : process.env.REACT_APP_ADMIN_URL;
const GIS_ROOT = `https://gis.developmentgateway.org/geoserver/vifaa/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=vifaa:africa&outputFormat=application/json`;

debugger;
export const getDataWithParams = (url, params) => {
  return new Promise((resolve, reject) => {
    post(url, params)
      .then(
        function(data) {
          resolve(data);
        }
      )
      .catch(function(err) {
        reject(err);
      });
  })
}

export const getData = async (url) => {
    const headers = {};
    headers['Accept-Language'] = 'fr';
    headers['Accept-Language'] = 'pt';
    return await get(url, {headers})
}

const post = (endpoint, params = {}, headers = {}) => {
  const url = `${API_ROOT_URL}${endpoint}`;
  return new Promise((resolve, reject) => {
    fetch(url, {
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', ...headers
      },
      method: 'POST',
      body: JSON.stringify(params)
    })
      .then(
        function (response) {
          if (response.status !== 200) {
            reject(response)
          }
          response.json().then(function (data) {
            resolve(data)
          }).catch((err) => reject(err))
        }
      )
      .catch(function (err) {
        reject(err)
      })
  })
}

const get = async (endpoint, params = {}, headers = {}) => {
    const url = `${API_ROOT_URL}${endpoint}`;
    try {
        const response = await fetch(url, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }
        });
        if (response.status !== 200) {
            throw new Error('Request failed');
        }
        return await response.json();
    } catch (error) {
        throw error;
    }

};

export const getMap = (endpoint, params = {}, headers = {}) => {
  const url = `${GIS_ROOT}`;
  console.log('url:', url);
  return new Promise((resolve, reject) => {
    fetch(url, {'countryIso': null})
      .then(
        function (response) {
          if (response.status !== 200) {
            reject(response)
          }
          response.json().then(function (data) {
            resolve(data)
          }).catch(function (err) {
            console.log(err);
          })
        }
      )
      .catch(function (err) {
        reject(err)
      })
  })
}
